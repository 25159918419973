import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import FestivalHeader from "../components/festival/festival-header"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import SectionTitle from "../components/layout/section-title"
import SEO from "../components/seo/seo"
import PartnerGroup from "../components/festival/partner-group"
import Thumbnail from "../components/thumbnail/thumbnail"

class InformationTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()
    }

    animate(){
      this.pageTransition.current.play()
    }

    render(){

        const { location } = this.props.data
        const locale = this.props.pageContext.locale
        const featured = location.generalInfo.slice(0, 2)
        const info = location.generalInfo.slice(2)
        return (
          <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
            <SEO title={location.title} />
            <FestivalNavigation locale={locale} links={location.navigation.links} color={'#F5867B'} />
            <FestivalHeader wide={location.theme.widescreenHeaderImage.file.url} portrait={location.theme.portraitHeaderImage.file.url} animation={location.theme.animation.file.url}>
              <div className={"container mx-auto py-20 sm:py-4 md:py-0 lg:py-20 text-center leading-none"}>
                <p className={"font-formula-bold text-lg lg:text-2xl text-white uppercase tracking-wider"}>{`the drop festival ${location.title}`}</p>
                <p className={"font-montserrat-extra-bold text-3xl md:text-7xl lg:text-9xl text-white uppercase"}>general info</p>
              </div>
            </FestivalHeader>
            <FestivalBackground wide={location.theme.widescreenBackground.file.url}>
                <div className={"container mx-auto py-4"}>
                  <SectionTitle title={"The Drop Information"} position={"center"} />
                  <div className={"flex flex-wrap"}>
                    {
                      featured.map((item, index) => {
                        return(
                          <div className={"w-full md:w-1/2 p-4"} key={index}>
                            <Link to={`/${locale}/festival-articles/${item.slug}/`}>
                              <Thumbnail
                                  title={item.title}
                                  image={item.image.file.url}
                                  description={item.short}
                                  height={320}
                                  details={true}
                              />
                            </Link>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={"flex flex-wrap"}>
                  {
                      info.map((item, index) => {
                          return(
                          <div className={"w-full md:w-1/3 p-4"} key={index}>
                            <Link to={`/${locale}/festival-articles/${item.slug}/`}>
                              <Thumbnail
                                  title={item.title}
                                  image={item.image.file.url}
                                  description={item.short}
                                  details={true}
                              />
                            </Link>
                          </div>
                          )
                      })
                  }
                    <div className={"w-full md:w-1/3 p-4"}>
                        <Link to={`/${locale}/locations/${location.slug}/faq/`}>
                          <Thumbnail
                              title={"FAQs"}
                              image={"https://images.ctfassets.net/8ipgrsmep6cq/sUKtdfP2ZkhuzuTZ5ZBoK/3b3f5555ff4c19de6346dd3ec933a778/faq-image.jpg"}
                              description={"List of frequently asked questions"}
                              details={true}
                          />
                        </Link>
                    </div>
                  </div>
                </div>
                <PartnerGroup partners={location.partners} locale={locale} />
            </FestivalBackground>
            <TransitionPortal>
              <PageTransition ref={this.pageTransition} />
            </TransitionPortal>
          </Layout>
        )
    }
}
//https://images.ctfassets.net/8ipgrsmep6cq/sUKtdfP2ZkhuzuTZ5ZBoK/3b3f5555ff4c19de6346dd3ec933a778/faq-image.jpg
export const pageQuery = graphql`
query LocationInfoPageQuery($slug: String!, $locale: String!) {
  location:contentfulLocation(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    title
    slug
    date
    venue
    generalInfo{
      ...on ContentfulFestivalArticle{
        __typename
        title
        slug
        short
        image{
          file{
            url
          }
        }
      }
    }
    partners{
      ...on ContentfulPartner{
        slug
        logo{
          file{
            url
          }
        }
      }
    }
    navigation{
      ...on ContentfulNavigation{
        links{
          ...on ContentfulLink{
            title
            href
          }
        }
      }
    }
    theme{
      ...on ContentfulTheme{
        logo{
          file{
            url
          }
        }
        animation{
          file{
            url
          }
        }
        portraitBackground{
          file{
            url
          }
        }
        portraitHeaderImage{
          file{
            url
          }
        }
        widescreenBackground{
          file{
            url
          }
        }
        widescreenHeaderImage{
          file{
            url
          }
        }
        colorPrimary
        colorSecondary
      }
    }
  }
}
`

export default InformationTemplate
